import React from "react";
import profile from "../../assets/images/profile.png";
import "./Profile.css";

const Profile = () => (
  <div className="profile-container">
    <img src={profile} className="profile-image" alt="Stanisław Radoń"></img>
    <div className="main-thought-container">
      <span className="main-thought">
        „Po przebytej chorobie stałem się, jeszcze lepszym lekarzem”
      </span>
      <span className="main-thought-author">Stanisław Radoń</span>
    </div>
  </div>
);

export default Profile;
