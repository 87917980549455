import React from "react";
import { EnvelopeFill, TelephoneFill } from "react-bootstrap-icons";
// import Logo from "../../logo/Logo";

const Contact = () => (
  <div className="row">
    <div className="col-xl-12 col-lg-12 col-md main">
      <span className="underline">S-Radon</span>
    </div>
    <div className="col-xl-12 col-lg-12 col-md sub">
      <p>Zapraszamy do kontaktu</p>
      {/* <p>
        <a href="mailto:kontakt@salve-regina.pl">
          <EnvelopeFill /> kontakt@salve-regina.pl
        </a>
      </p> */}
      <p>
        <a href="tel:+48 158 377 212">
          <TelephoneFill /> +48 158 377 212
        </a>
      </p>
    </div>
  </div>
);

export default Contact;
