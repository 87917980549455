import React, { useState, useEffect } from "react";
import "./Navigation.css";
const Navigation = ({ linkedSections }) => {
  console.log("nav: ", linkedSections);
  const [currentSection, setCurrentSection] = useState(null);

  const scrollToItem = (id) => {
    const el = document.querySelector(`#${id}`);
    if (el) {
      const { y } = el.getBoundingClientRect();
      document.querySelector(".App").scrollBy({
        top: y,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (linkedSections.length !== 0) {
      const app = document.querySelector(".App");
      const handleScroll = () => {
        let intersected = null;
        linkedSections.forEach((section) => {
          const { id } = section;
          const { top, bottom } = section.getBoundingClientRect();

          console.table({ id, top, bottom });
          if (Math.trunc(top) <= 0)
            if (bottom >= 0) {
              intersected = section;
              return;
            }
        });

        // zmienia kilka razy podczasz scrolla, działa ale wypada poprawić
        if (currentSection) currentSection.style.color = "white";

        if (intersected) {
          const newSection = document.querySelector(`#${intersected.id}-li`);
          newSection.style.color = "grey";
          setCurrentSection(newSection);
        }

        const topSection = document.querySelector(".profile-container");
        const topSectionHeight = topSection.getBoundingClientRect().height;
        console.log("scrollTop: ", app.scrollTop);
        console.log("topSection: ", topSectionHeight);
      };
      app.addEventListener("scroll", () => handleScroll());
      app.removeEventListener("scroll", () => handleScroll());
    }
  }, [linkedSections, currentSection]);

  return (
    <nav className="navigation">
      <ul>
        <li
          id="moje-osiagniecia-li"
          onClick={() => scrollToItem("moje-osiagniecia")}
        >
          Osiągnięcia
        </li>
        <li
          id="patenty-medyczne-li"
          onClick={() => scrollToItem("patenty-medyczne")}
        >
          Patenty Medyczne
        </li>
        <li
          id="patenty-rolnicze-li"
          onClick={() => scrollToItem("patenty-rolnicze")}
        >
          Patenty Rolnicze
        </li>
        <li id="kontakt-li" onClick={() => scrollToItem("kontakt")}>
          Kontakt
        </li>
      </ul>
    </nav>
  );
};
export default Navigation;
