import React from "react";
import "./Card.css";

import data from "../../assets/data.json";
const Card = ({ id, icon, alt, heading, collection, bg, signature }) => {
  const items = data.Patenty[collection];
  return (
    <div
      className="rod-container scroll-snap-item"
      id={id}
      style={{
        backgroundImage: `url(${bg})`,
      }}
    >
      <div
        className="rod-container-bg"
        style={{
          backgroundImage: `url(${bg})`,
        }}
      >
        {" "}
      </div>
      <div className="rod-info">
        <div className="header">
          <img src={icon} alt={alt} className="rod-header-icon" />
          <h1>{heading}</h1>
        </div>
        <ul>
          {items.length > 0 &&
            items.map((item, i) => (
              <li key={i}>
                {/* <div className="img-container"> */}
                <img
                  src={require(`../../assets/${item.src}`)}
                  alt={alt}
                  className="rod-icon"
                />
                {/* </div> */}
                <div className="text-container">
                  <h3>{item.title}</h3>
                  <p>{item.text}</p>
                  {/* <button className="btn btn-outline-primary nbstr_btn">
                    VIEW RECENT EPISODES
                  </button> */}
                </div>
              </li>
            ))}
            {/* {signature && <>
              <br/>
            <li>
            <div className="text-container" style={{width:"100%", paddingLeft: 0}}>
                  <h4 className="sigt" style={{lineHeight: "2.25rem"}}>Do osiągnięć podanych jak wyżej dodaję z wielką radością o tym,<br/>
                    że zostałem zgłoszony do umieszczenia w <br/><b>Encyklopedii Osobistości 
                    Rzeczypospolitej Polskiej wydanie 10,</b><br/> które będzie wydane w grudniu 2024.
                    </h4>
                  </div>
                  </li>
            <br/>
            <li> 
            <div className="text-container" style={{width:"100%", paddingLeft: 0}}>
                  <h4 className="sigt">Wszystkim firmom udostępnie moje patenty</h4><h4 className="sigt"> aby produkowały moje wynalazki dla ludzkości całego świata!</h4>
                  <p className="sig">Z poważaniem</p>
                  <p className="sig signature">Stanisław Radoń</p>
                  </div>
                  </li>
                  </>} */}

        </ul>
      </div>
    </div>
  );
};
export default Card;
