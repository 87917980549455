import React from "react";
import banner from "../../assets/images/banner.jpg";
import "./Banner.css";

const Banner = () => (
  <div id="moje-osiagniecia" className="banner-container scroll-snap-item">
    <h1>Moje Osiągnięcia</h1>
    <img src={banner} className="banner-image" alt="Banner"></img>
  </div>
);

export default Banner;
