import React from "react";

const Address = () => (
  <div className="row">
    <div className="col-xl-12 col-lg-12 col-md main nl mb-1">
      <span className="underline">Adres</span>
    </div>
    <div className="col-xl-12 col-lg-12 col-md sub">
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://goo.gl/maps/WZkXZFZJnZnY3WNv6"
      >
        <p>Wilczyce 173</p>
        <p>Zakład opieki</p>
        <p>zdrowotnej</p>
      </a>
    </div>
  </div>
);

export default Address;
