import React from "react";
import {
  // EnvelopeFill,
  TelephoneFill,
  GeoAltFill,
} from "react-bootstrap-icons";
import "./Contact.css";
// import ContactForm from "./contact-form/ContactForm";
// import Nav from "../nav/Nav";
const Contact = () => (
  <>
    {/* <Nav /> */}
    <div
      className="container contact-container scroll-snap-item"
      id="kontakt"
      style={{
        margin: "0 auto",
      }}
    >
      <h1
        style={{
          marginBottom: "1.5rem",
        }}
      >
        <i>Zapraszamy do kontaktu</i>
      </h1>
      {/* <br /> */}
      <div className="row pt-5 ">
        <div className="col-lg center-content nbstr_card">
          <div className="card-border wd70 h250">
            <div className="center-content">
              <div className="center-content contact-card">
                <div className="contact-icon">
                  <TelephoneFill />
                </div>
              </div>
            </div>
            <br />
            <p className="contact-title">Zadzwoń</p>
            <a href="tel:+48 507 137 012">
              <p>+48 507 137 012</p>
            </a>
          </div>
        </div>
        {/* <div className="col-lg center-content nbstr_card">
          <div className="card-border wd70 h250">
            <div className="center-content">
              <div className="center-content contact-card">
                <div className="contact-icon">
                  <EnvelopeFill />
                </div>
              </div>
            </div>
            <br />
            <p className="contact-title">Napisz</p>
            <a href="mailto:kontakt@salve-regina.pl">
              <p>kontakt@salve-regina.pl</p>
            </a>
          </div>
        </div> */}
        <div className="col-lg center-content nbstr_card">
          <div className="card-border wd70 h250">
            <div className="center-content">
              <div className="center-content contact-card">
                <div className="contact-icon">
                  <GeoAltFill />
                </div>
              </div>
            </div>
            <br />
            <p className="contact-title">Odwiedź</p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://goo.gl/maps/WZkXZFZJnZnY3WNv6"
            >
              <p>Wilczyce 173</p>
              <p>Zakład opieki zdrowotnej</p>
            </a>
          </div>
        </div>
        <div className="col-lg">
          <iframe
            title="google-maps"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2524.644911591061!2d21.654985415742065!3d50.74507447951704!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4722b3d7088eb4eb%3A0x1802bece41ebdc95!2sO%C5%9Brodek%20Zdrowia%20Wilczyce!5e0!3m2!1sen!2spl!4v1666783947277!5m2!1sen!2spl"
            loading="lazy"
          ></iframe>
        </div>
      </div>
      <br />
      <div>
        {/* <div className="row">
          <div className="col-lg">
            <ContactForm />
            </div>
          <div className="col-lg">
            <iframe
              title="google-maps"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2524.644911591061!2d21.654985415742065!3d50.74507447951704!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4722b3d7088eb4eb%3A0x1802bece41ebdc95!2sO%C5%9Brodek%20Zdrowia%20Wilczyce!5e0!3m2!1sen!2spl!4v1666783947277!5m2!1sen!2spl"
              loading="lazy"
            ></iframe>
          </div>
        </div> */}
      </div>
    </div>
  </>
);

export default Contact;
