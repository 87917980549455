import React from "react";
import "./Footer.css";
import Contact from "./contact/Contact";
import OpenHours from "./open-hours/OpenHours";
import Address from "./address/Address";
import Copyright from "./copyright/Copyright";
const Footer = () => (
  <footer className="scroll-snap-item">
    <div className="footer-contact">
      <div className="row">
        <div className="col-xl col-lg-3 move-right">
          <OpenHours />
        </div>
        <div className="col-xl col-lg-4 move-right">
          <Contact />
        </div>
        <div className="col-xl col-lg-3 move-right nbstrp_div">
          <Address />
        </div>
        <div className="col-xl-2 col-lg-2 col-md-12 map">
          <iframe
            title="google-maps"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2524.644911591061!2d21.654985415742065!3d50.74507447951704!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4722b3d7088eb4eb%3A0x1802bece41ebdc95!2sO%C5%9Brodek%20Zdrowia%20Wilczyce!5e0!3m2!1sen!2spl!4v1666783947277!5m2!1sen!2spl"
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </div>
    <Copyright />
  </footer>
);

export default Footer;
