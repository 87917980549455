import React from "react";

const OpenHours = () => (
  <div className="row">
    <div className="col-xl-12 col-lg-12 col-md open-hours main nl">
      <span className="underline">Godziny Otwarcia</span>
    </div>
    <div className="col-xl-12 col-lg-12 col-md sub">
      <p>Pn-Pt: 8 - 16</p>
      <p>Sobota: 8 - 12</p>
    </div>
  </div>
);

export default OpenHours;
