import React, { useState, useEffect } from "react";
import Profile from "../profile/Profile";
import Banner from "../banner/Banner";
import Signature from "../signature/Signature";
import Card from "../card/Card";
import Navigation from "../navigation/Navigation";
import Footer from "../footer/Footer";

// ikony
import rod from "../../assets/images/rod.png";
import wheat from "../../assets/images/wheat.png";
import Contact from "../contact/Contact";
// tła
import sciencebg from "../../assets/images/background/science1.jpg";
import fieldbg from "../../assets/images/background/field1-cut.jpg";

const Home = () => {
  const [linkedSections, setLinkedSections] = useState([]);

  useEffect(() => {
    setLinkedSections([
      document.querySelector("#moje-osiagniecia"),
      document.querySelector("#patenty-medyczne"),
      document.querySelector("#patenty-rolnicze"),
      document.querySelector("#kontakt"),
    ]);
  }, []);

  return (
    <div className="App">
      <Profile />
      <Navigation linkedSections={linkedSections} />
      <Banner />
      <Card
        id="patenty-medyczne"
        icon={rod}
        alt="kostur lekarski"
        heading="Patenty Medyczne"
        collection="Medyczne"
        align="left"
        signature={true}
        bg={sciencebg}
      />
      <Card
        id="patenty-rolnicze"
        icon={wheat}
        alt="dźbło zboża"
        heading="Patenty Rolnicze"
        collection="Rolnicze"
        align="right"
        bg={fieldbg}
      />
      <Signature />    
      <Contact />
      <Footer />
    </div>
  );
};

export default Home;
